// src/pages/Mission.js
import React from "react";
import { motion } from "framer-motion";

// Placeholder image imports (replace with actual images as needed)
import missionHeroImage from "../images/misionHero.jpg";
import empowermentImage from "../images/power.png";
import communityImage from "../images/communityWomen.png";
import growthImage from "../images/holisticGrowth-.png";

const Mission = () => {
  return (
    <div className="mission-page">
      {/* Hero Section */}
      <section
        className="relative h-96 bg-cover bg-center flex items-center justify-center text-center text-white"
        style={{ backgroundImage: `url(${missionHeroImage})` }}
      >
        <div className="bg-black/50 absolute inset-0"></div>
        <motion.div
          className="relative z-10 max-w-3xl px-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <h1 className="text-5xl font-bold mb-4">Our Mission</h1>
          <p className="text-lg md:text-xl">
            At Goddess Global Foundation, we are dedicated to empowering women worldwide by nurturing their beauty, wellness, and inner strength. Join us in creating a world where every woman can embrace her purpose.
          </p>
        </motion.div>
      </section>

      {/* Our Vision Section */}
      <section className="py-20 px-4 bg-white text-center">
        <div className="max-w-5xl mx-auto">
          <h2 className="text-4xl font-bold text-gray-900 mb-8">Our Vision</h2>
          <p className="text-lg text-gray-700 leading-relaxed">
            We envision a world where every woman feels empowered to embrace her full potential and live with confidence, balance, and self-love. At the heart of our mission is a commitment to holistic growth—encouraging women to thrive physically, mentally, and spiritually.
          </p>
        </div>
      </section>

      {/* Empowerment Section */}
      <section className="py-20 px-4 bg-gray-100">
        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <motion.img
            src={empowermentImage}
            alt="Empowerment"
            className="w-full h-72 object-cover object-top rounded-lg shadow-md" // Added object-top
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          />
          <div>
            <h2 className="text-4xl font-bold text-gray-900 mb-4">Empowering Every Woman</h2>
            <p className="text-lg text-gray-700 leading-relaxed">
              Empowerment lies at the core of our foundation. Through beauty, fashion, wellness, and spiritual growth, we help women find their inner strength and project it outwardly. We believe that self-expression and self-care are vital in cultivating confidence and resilience.
            </p>
          </div>
        </div>
      </section>



      {/* Community & Connection Section */}
      <section className="py-20 px-4 bg-white">
        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div>
            <h2 className="text-4xl font-bold text-gray-900 mb-4">Building Community & Connection</h2>
            <p className="text-lg text-gray-700 leading-relaxed">
              Goddess Global Foundation is more than just a mission; it’s a community of women supporting each other. We host events, workshops, and retreats that encourage meaningful connections, networking, and mutual support, creating a sisterhood that uplifts and inspires.
            </p>
          </div>
          <motion.img
            src={communityImage}
            alt="Community"
            className="w-full h-72 object-cover rounded-lg shadow-md"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          />
        </div>
      </section>

      {/* Holistic Growth Section */}
      <section className="py-20 px-4 bg-gray-100 text-center">
        <div className="max-w-5xl mx-auto">
          <h2 className="text-4xl font-bold text-gray-900 mb-8">Holistic Growth</h2>
          <p className="text-lg text-gray-700 leading-relaxed mb-8">
            Our holistic approach combines beauty, wellness, and spirituality to support each woman’s journey. We believe in the power of nurturing both the mind and body to promote self-discovery, peace, and personal growth.
          </p>
          <motion.img
            src={growthImage}
            alt="Holistic Growth"
            className="w-full h-72 object-cover rounded-lg shadow-md mx-auto"
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8 }}
          />
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="py-20 px-4 bg-rose-500 text-center text-white">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-4xl font-bold mb-4">Join Our Movement</h2>
          <p className="text-lg mb-8">
            Become part of a growing network of empowered women. Whether you want to attend an event, volunteer, or support our mission, there’s a place for you in our community. Together, we can make a lasting impact.
          </p>
          <button className="bg-white text-rose-500 px-6 py-3 rounded-full font-semibold hover:bg-gray-100 transition">
            Get Involved
          </button>
        </div>
      </section>
    </div>
  );
};

export default Mission;
