// src/components/Navbar.js
import { Link } from "react-router-dom";
import logo from "../images/goddess_global_logo.png"; // Import the logo image

function Navbar() {
  return (
    <nav className="bg-blush p-4">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo and Brand Name */}
        <Link to="/" className="flex items-center">
          <img
            src={logo}
            alt="Goddess Global Logo"
            className="h-20 mr-2" // Adjust the height as needed (e.g., h-12, h-14)
          />
          <h1 className="text-roseGold text-2xl font-bold">Goddess Global</h1>
        </Link>

        {/* Navigation Links */}
        <ul className="flex space-x-4 text-mauve">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/about">About</Link></li>
          <li><Link to="/mission">Mission</Link></li>
          <li><Link to="/donate">Donate</Link></li>
          <li><Link to="/contact">Contact</Link></li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
