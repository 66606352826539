// src/App.js
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer"; // Add this if Footer is a component
import Home from "./pages/Home";
import About from "./pages/About";
import Mission from "./pages/Mission";
import Donate from "./pages/Donate";
import Contact from "./pages/Contact";

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/mission" element={<Mission />} />
          <Route path="/donate" element={<Donate />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer /> {/* Move Footer here inside the return statement */}
      </div>
    </Router>
  );
}

export default App;
