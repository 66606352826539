// src/components/Footer.js
function Footer() {
    return (
      <footer className="bg-softPink p-4 text-center">
        <p className="text-mauve">&copy; 2024 Goddess Global Foundation</p>
        <div className="flex justify-center space-x-4 mt-2">
          <a href="#" className="text-roseGold">Facebook</a>
          <a href="#" className="text-roseGold">Instagram</a>
          <a href="#" className="text-roseGold">Twitter</a>
        </div>
      </footer>
    );
  }
  
  export default Footer;
  