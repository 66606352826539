// src/pages/Donate.js
import React, { useState } from "react";
import { motion } from "framer-motion";
import donationHeroImage from "../images/donate.jpg"; // Placeholder image

const Donate = () => {
  const [amount, setAmount] = useState(""); // Donation amount

  const handleDonation = (selectedAmount) => {
    setAmount(selectedAmount);
  };

  return (
    <div className="donation-page">
      {/* Hero Section */}
      <section
        className="relative h-80 bg-cover bg-center flex items-center justify-center text-center text-white"
        style={{ backgroundImage: `url(${donationHeroImage})` }}
      >
        <div className="bg-black/50 absolute inset-0"></div>
        <motion.div
          className="relative z-10 max-w-3xl px-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <h1 className="text-5xl font-bold mb-4">Support Our Mission</h1>
          <p className="text-lg md:text-xl">
            Your donation helps us empower women to live confidently, healthily, and purposefully. Join us in making a lasting impact.
          </p>
        </motion.div>
      </section>

      {/* Donation Options */}
      <section className="py-16 px-4 bg-white text-center">
        <h2 className="text-4xl font-bold text-gray-900 mb-8">Choose Your Donation Amount</h2>
        <div className="max-w-4xl mx-auto grid grid-cols-2 md:grid-cols-4 gap-8">
          {[10, 25, 50, 100].map((amt) => (
            <button
              key={amt}
              onClick={() => handleDonation(amt)}
              className={`p-6 rounded-lg border-2 ${amount === amt ? "border-rose-500" : "border-gray-300"} hover:border-rose-400 transition`}
            >
              <p className="text-2xl font-bold text-roseGold">${amt}</p>
            </button>
          ))}
          <input
            type="number"
            placeholder="Custom"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            className="p-6 rounded-lg border-2 border-gray-300 text-center text-2xl font-bold focus:border-rose-400"
          />
        </div>
        <button className="mt-8 bg-rose-500 text-white px-8 py-3 rounded-full font-semibold hover:bg-rose-600 transition">
          Donate ${amount || "Amount"}
        </button>
      </section>

      {/* Impact Statement */}
      <section className="py-16 px-4 bg-gray-100">
        <div className="max-w-5xl mx-auto text-center">
          <h2 className="text-4xl font-bold text-gray-900 mb-6">Your Impact</h2>
          <p className="text-lg text-gray-700 mb-8 leading-relaxed">
            Each dollar you give brings us closer to building a world where every woman feels empowered, confident, and connected. Your contributions support essential programs in wellness, community building, and holistic growth.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-2xl font-bold text-roseGold mb-2">$10</h3>
              <p className="text-gray-700">Provides self-care resources to women in need.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-2xl font-bold text-roseGold mb-2">$50</h3>
              <p className="text-gray-700">Supports workshops for personal and professional growth.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-2xl font-bold text-roseGold mb-2">$100</h3>
              <p className="text-gray-700">Funds community events that foster connection and empowerment.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="py-16 px-4 bg-rose-500 text-center text-white">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-4xl font-bold mb-4">Join the Movement</h2>
          <p className="text-lg mb-8">
            Together, we can create lasting change. Your support means the world to us and to the women whose lives are transformed by our programs.
          </p>
          <button className="bg-white text-rose-500 px-6 py-3 rounded-full font-semibold hover:bg-gray-100 transition">
            Become a Monthly Donor
          </button>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="py-16 px-4 bg-white text-center">
        <div className="max-w-5xl mx-auto">
          <h2 className="text-4xl font-bold text-gray-900 mb-8">Frequently Asked Questions</h2>
          <div className="text-left">
            <h3 className="text-2xl font-semibold text-rose-500 mb-2">How are donations used?</h3>
            <p className="text-gray-700 mb-6">
              Donations are directed towards our core programs: wellness, empowerment workshops, community events, and outreach. We ensure every dollar has a meaningful impact.
            </p>
            <h3 className="text-2xl font-semibold text-rose-500 mb-2">Is my donation tax-deductible?</h3>
            <p className="text-gray-700 mb-6">
              Yes, Goddess Global Foundation is a registered nonprofit, and all donations are tax-deductible to the extent allowed by law.
            </p>
            <h3 className="text-2xl font-semibold text-rose-500 mb-2">Can I make a recurring donation?</h3>
            <p className="text-gray-700">
              Absolutely! Monthly donations provide sustained support and allow us to plan long-term initiatives. Simply select the monthly donation option.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Donate;
