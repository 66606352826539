// src/pages/Contact.js
import React from "react";
import { motion } from "framer-motion";
import heroImage from "../images/contactHero.jpg"; // Placeholder for the hero background image

const Contact = () => {
  return (
    <div className="contact-page">
      {/* Hero Section */}
      <section
        className="relative h-80 bg-cover bg-center flex items-center justify-center text-center text-white"
        style={{ backgroundImage: `url(${heroImage})` }}
      >
        <div className="bg-black/50 absolute inset-0"></div>
        <motion.div
          className="relative z-10 max-w-3xl px-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <h1 className="text-5xl font-bold mb-4">Get in Touch</h1>
          <p className="text-lg md:text-xl">
            We’d love to hear from you! Whether you have questions, feedback, or just want to connect, reach out to us.
          </p>
        </motion.div>
      </section>

      {/* Contact Form Section */}
      <section className="py-16 px-4 bg-gray-100">
        <div className="max-w-5xl mx-auto">
          <h2 className="text-4xl font-bold text-center text-gray-900 mb-8">Contact Us</h2>
          <form className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="col-span-1">
              <label className="text-gray-700 font-semibold">Your Name</label>
              <input
                type="text"
                className="w-full mt-2 p-3 rounded-lg border border-gray-300 focus:border-rose-400 focus:ring-rose-400"
                placeholder="Enter your name"
              />
            </div>
            <div className="col-span-1">
              <label className="text-gray-700 font-semibold">Email Address</label>
              <input
                type="email"
                className="w-full mt-2 p-3 rounded-lg border border-gray-300 focus:border-rose-400 focus:ring-rose-400"
                placeholder="Enter your email"
              />
            </div>
            <div className="col-span-2">
              <label className="text-gray-700 font-semibold">Subject</label>
              <input
                type="text"
                className="w-full mt-2 p-3 rounded-lg border border-gray-300 focus:border-rose-400 focus:ring-rose-400"
                placeholder="Enter subject"
              />
            </div>
            <div className="col-span-2">
              <label className="text-gray-700 font-semibold">Message</label>
              <textarea
                className="w-full mt-2 p-3 rounded-lg border border-gray-300 focus:border-rose-400 focus:ring-rose-400"
                rows="6"
                placeholder="Write your message here"
              ></textarea>
            </div>
            <div className="col-span-2 text-center">
              <button className="bg-rose-500 text-white px-8 py-3 rounded-full font-semibold hover:bg-rose-600 transition">
                Send Message
              </button>
            </div>
          </form>
        </div>
      </section>

      {/* Foundation Info Section */}
      <section className="py-16 px-4 bg-white">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-4xl font-bold text-gray-900 mb-8">Our Information</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="p-6 bg-gray-100 rounded-lg shadow-md">
              <h3 className="text-2xl font-bold text-roseGold mb-2">Email Us</h3>
              <p className="text-gray-700">info@goddessglobal.org</p>
            </div>
            <div className="p-6 bg-gray-100 rounded-lg shadow-md">
              <h3 className="text-2xl font-bold text-roseGold mb-2">Call Us</h3>
              <p className="text-gray-700">+1 (555) 123-4567</p>
            </div>
            <div className="p-6 bg-gray-100 rounded-lg shadow-md">
              <h3 className="text-2xl font-bold text-roseGold mb-2">Visit Us</h3>
              <p className="text-gray-700">12345 Somewhere Lane, Suite 100, Los Angeles, CA</p>
            </div>
          </div>
        </div>
      </section>

      {/* Social Media Section */}
      <section className="py-16 px-4 bg-rose-500 text-center text-white">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-4xl font-bold mb-4">Connect with Us</h2>
          <p className="text-lg mb-8">
            Follow us on social media to stay updated with our latest events, programs, and community stories.
          </p>
          <div className="flex justify-center space-x-6">
            <a href="https://facebook.com" className="text-white hover:text-gray-200 transition">
              <i className="fab fa-facebook-f text-3xl"></i>
            </a>
            <a href="https://twitter.com" className="text-white hover:text-gray-200 transition">
              <i className="fab fa-twitter text-3xl"></i>
            </a>
            <a href="https://instagram.com" className="text-white hover:text-gray-200 transition">
              <i className="fab fa-instagram text-3xl"></i>
            </a>
            <a href="https://linkedin.com" className="text-white hover:text-gray-200 transition">
              <i className="fab fa-linkedin-in text-3xl"></i>
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
