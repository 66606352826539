// src/pages/About.js
import React from "react";
import { motion } from "framer-motion";

// Import placeholders for images (replace with actual images as needed)
import mekkeImage from "../images/Profile.png";
import journeyImage from "../images/journey.jpg";
import communityImage from "../images/community.jpg";
import missionImage from "../images/mekkel.png";
import wellnessImage from "../images/wellness.jpg";
import empowerImage from "../images/power.jpg";
import impactImage from "../images/impact.jpg";


const About = () => {
  return (
    <div className="about-page">
      {/* Founder Section */}
      <section className="relative py-24 px-4 bg-gray-100 text-center">
        <motion.img
          src={mekkeImage}
          alt="Mekke'l Carter - Founder"
          className="w-40 h-40 mx-auto rounded-full mb-4 object-cover"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1 }}
        />
        <h2 className="text-4xl font-bold text-gray-900 mb-4">Meet Mekke'l Carter</h2>
        <p className="text-lg text-gray-700 max-w-3xl mx-auto mb-8 leading-relaxed">
          Known as the Rose Gold Goddess, Mekke'l Carter is a visionary entrepreneur
          who founded the Goddess Global Foundation. With a deep commitment to empowering
          women through beauty, spirituality, and self-confidence, Mekke'l's journey
          is one of resilience and purpose. Her mission is to create a space where every
          woman can embrace her inner goddess and make a positive impact on her world.
        </p>
      </section>

      {/* Foundation's Journey Section */}
      <section className="py-24 px-4 bg-white">
        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <img
              src={journeyImage}
              alt="Our Journey"
              className="w-full h-full object-cover rounded-lg shadow-md"
            />
          </motion.div>
          <div>
            <h2 className="text-4xl font-bold text-gray-900 mb-4">Our Journey</h2>
            <p className="text-lg text-gray-700 leading-relaxed">
              The Goddess Global Foundation was born out of Mekke'l's desire to foster
              holistic empowerment for women. From small community gatherings to global
              events, the foundation has grown into a supportive network, offering
              resources in beauty, wellness, spirituality, and self-care.
            </p>
          </div>
        </div>
      </section>

      {/* Mission and Values Section */}
      <section className="py-24 px-4 bg-gray-100">
        <div className="max-w-6xl mx-auto text-center">
          <h2 className="text-4xl font-bold text-gray-900 mb-8">Our Mission</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <motion.div
              className="p-6 bg-white rounded-lg shadow-md transition-transform"
              whileHover={{ scale: 1.05 }}
            >
              <img src={empowerImage} alt="Empowerment" className="w-full h-32 object-cover rounded mb-4" />
              <h3 className="text-2xl font-bold text-gray-800 mb-2">Empowerment</h3>
              <p className="text-gray-600">
                We believe in empowering women to realize their full potential through
                holistic health, beauty, and self-expression.
              </p>
            </motion.div>
            <motion.div
              className="p-6 bg-white rounded-lg shadow-md transition-transform"
              whileHover={{ scale: 1.05 }}
            >
              <img src={communityImage} alt="Community" className="w-full h-32 object-cover rounded mb-4" />
              <h3 className="text-2xl font-bold text-gray-800 mb-2">Community</h3>
              <p className="text-gray-600">
                Building a supportive community where women uplift each other and
                embrace collective growth and empowerment.
              </p>
            </motion.div>
            <motion.div
              className="p-6 bg-white rounded-lg shadow-md transition-transform"
              whileHover={{ scale: 1.05 }}
            >
              <img src={wellnessImage} alt="Holistic Wellness" className="w-full h-32 object-cover rounded mb-4" />
              <h3 className="text-2xl font-bold text-gray-800 mb-2">Holistic Wellness</h3>
              <p className="text-gray-600">
                Offering a blend of beauty, wellness, and spirituality to support
                every woman’s journey to balance and fulfillment.
              </p>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Community Impact Section */}
      <section className="py-20 px-4 bg-white text-center">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-4xl font-bold text-gray-900 mb-8">Our Community Impact</h2>
          <p className="text-lg text-gray-700 mb-12 leading-relaxed">
            From workshops and wellness events to beauty makeovers and retreats, the
            Goddess Global Foundation has empowered thousands of women to lead lives
            filled with purpose, health, and self-love.
          </p>
          <motion.div
            className="w-full h-64 bg-cover bg-fill bg-top rounded-lg scale-50 shadow-lg"
            style={{ backgroundImage: `url(${impactImage})` }}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          />  
        </div>
      </section>
    </div>
  );
};

export default About;
