import React, { useEffect, useRef } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { FaLeaf, FaHeart, FaUsers, FaYinYang } from 'react-icons/fa'; // Importing icons from react-icons
import CountUp from 'react-countup'; // Importing CountUp for animated counters
import heroImage from "../images/mekkel_hero.png";
import wellnessImage from "../images/car1.png";
import fitnessImage from "../images/running.png";
import meditationImage from "../images/hero.jpg";
import spiritualImage from "../images/spiritual.png"

const Home = () => {
  const carouselRef = useRef(null);

  // Define the carousel images
  const carouselImages = [
    { id: 1, src: spiritualImage, alt: "Empowered women in community" },
    { id: 2, src: wellnessImage, alt: "Wellness workshop" },
    { id: 3, src: fitnessImage, alt: "Fitness session" },
    { id: 4, src: meditationImage, alt: "Meditation circle" },
  ];

  // Duplicate the array to create a seamless loop effect
  const extendedImages = [...carouselImages, ...carouselImages];

  // Auto-scrolling carousel effect
  useEffect(() => {
    let scrollAmount = 0;
    const scrollStep = 0.5; // Speed of scrolling

    const scrollCarousel = () => {
      if (carouselRef.current) {
        carouselRef.current.scrollLeft = scrollAmount;
        scrollAmount += scrollStep;

        // Reset scroll position to create a seamless loop
        if (scrollAmount >= carouselRef.current.scrollWidth / 2) {
          scrollAmount = 0;
        }
      }
    };

    const intervalId = setInterval(scrollCarousel, 20); // Adjust interval for smoothness

    return () => clearInterval(intervalId);
  }, []);

  // Hero section scroll animations
  const { scrollY: pageScrollY } = useScroll();
  const heroBackgroundY = useTransform(pageScrollY, [0, 500], [0, 150]);
  const heroBackgroundScale = useTransform(pageScrollY, [0, 500], [1, 1.2]);
  const heroOpacity = useTransform(pageScrollY, [0, 300], [1, 0]);

  return (
    <div className="relative bg-slate-50">
      {/* Hero Section with Parallax */}
      <section
        id="hero"
        className="relative h-screen flex items-center justify-center overflow-hidden"
      >
        <motion.div
          className="absolute inset-0 bg-cover bg-top"
          style={{
            backgroundImage: `url(${heroImage})`,
            backgroundPosition: "top", // Align the background image to show more of the top
            backgroundSize: "cover",
            y: heroBackgroundY,
            scale: heroBackgroundScale
          }}
        />
        <motion.div
          className="absolute inset-0 bg-black/40 z-10"
          style={{ opacity: heroOpacity }}
        />

        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          className="relative z-20 text-center px-4"
          style={{ opacity: heroOpacity }}
        >
          <h1 className="text-6xl md:text-8xl font-bold text-white mb-6">
            Empower
            <span className="block text-rose-300">the Goddess</span>
            Within
          </h1>
          <p className="text-xl md:text-2xl text-white/90 mb-8 max-w-2xl mx-auto">
            Join our community of empowered women creating positive change through wellness, creativity, and purpose.
          </p>
          <motion.button
            onClick={() => console.log('Navigating to Join')}
            className="inline-block bg-gradient-to-r from-rose-400 to-pink-500 text-white px-8 py-4 rounded-full text-lg font-semibold hover:shadow-lg transition-transform"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Join Our Movement
          </motion.button>
        </motion.div>
      </section>

      {/* Auto-scrolling Image Carousel Section */}
      <section id="carousel" className="relative py-24 overflow-hidden bg-black">
        <motion.h2
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-4xl md:text-5xl font-bold text-white text-center mb-12"
        >
          Our Community
        </motion.h2>

        <div ref={carouselRef} className="flex gap-4 overflow-x-hidden whitespace-nowrap px-4">
          {extendedImages.map((image, index) => (
            <div
              key={`${image.id}-${index}`}
              className="flex-none w-96 h-64 relative rounded-lg overflow-hidden"
            >
              <img
                src={image.src}
                alt={image.alt}
                className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-500"
              />
            </div>
          ))}
        </div>
      </section>

      {/* Mission Statement Section */}
      <section id="mission" className="relative py-24 px-4 bg-mauve-light">
        <div className="max-w-6xl mx-auto text-center">
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.8 }}>
            <div className="flex justify-center mb-6">
              <div className="w-16 h-1 bg-rose-300"></div>
            </div>
            <h2 className="text-4xl md:text-5xl font-bold text-gray-900 mb-8">Our Mission</h2>
            <p className="text-xl md:text-2xl text-gray-700 max-w-3xl mx-auto leading-relaxed">
              The Goddess Global Foundation empowers women worldwide through holistic approaches
              that embrace beauty, fashion, fitness, wellness, and spiritual growth.
            </p>
            <div className="flex justify-center mt-6">
              <div className="w-16 h-1 bg-rose-300"></div>
            </div>
          </motion.div>
        </div>
      </section>

      <section id="highlights" className="relative py-24 px-4 bg-white">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {[
              {
                title: "Beauty & Fashion",
                description: "Express your inner beauty with our FACEGOD cosmic-themed cosmetics.",
                color: "bg-rose-100",
                path: "/beauty"
              },
              {
                title: "Fitness & Wellness",
                description: "Empowering fitness and well-being with Sugar Sweat athletic gear.",
                color: "bg-purple-100",
                path: "/fitness"
              },
              {
                title: "Holistic Health",
                description: "Explore holistic health with Goddess Ganja and Mekke'l's Magik Mushrooms.",
                color: "bg-pink-100",
                path: "/health"
              },
              {
                title: "Spiritual Growth",
                description: "Connect with your inner goddess through meditation and mindfulness.",
                color: "bg-teal-100",
                path: "/spiritual-growth"
              }
            ].map((item, index) => (
              <motion.div
                key={item.title}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                whileHover={{ scale: 1.03, transition: { duration: 0.2 } }}
              >
                <button
                  onClick={() => console.log(`Navigating to ${item.path}`)}
                  className={`block w-full text-left ${item.color} p-8 rounded-2xl bg-gradient-to-br from-white to-${item.color}-100 hover:shadow-xl`}
                >
                  <div className="mb-4">
                    <h3 className="text-2xl font-bold text-gray-900">{item.title}</h3>
                  </div>
                  <p className="text-gray-700">{item.description}</p>
                </button>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      <section className="py-16 bg-gray-100 text-center">
        <h2 className="text-4xl font-bold mb-8 text-gray-900">Why Choose Us</h2>
        <div className="max-w-6xl mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {[
            { title: "Holistic Approach", icon: FaLeaf, description: "We empower through beauty, wellness, and spirituality." },
            { title: "Community-Driven", icon: FaHeart, description: "A network of women uplifting each other." },
            { title: "Empowering Every Woman", icon: FaUsers, description: "Fostering self-confidence and resilience." },
            { title: "Mind & Body Balance", icon: FaYinYang, description: "Helping women achieve harmony within." }
          ].map((item, index) => (
            <motion.div
              key={index}
              whileHover={{ scale: 1.05 }}
              className="p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition"
            >
              <item.icon className="text-rose-400 text-4xl mb-4 mx-auto" />
              <h3 className="text-xl font-bold mb-2 text-gray-800">{item.title}</h3>
              <p className="text-gray-600">{item.description}</p>
            </motion.div>
          ))}
        </div>
      </section>

      <section className="py-16 bg-white text-center">
        <h2 className="text-4xl font-bold text-gray-900 mb-8">Our Impact</h2>
        <div className="flex justify-center gap-16">
          {[
            { label: "Women Empowered", value: 1200 },
            { label: "Programs Hosted", value: 45 },
            { label: "Volunteers", value: 300 },
            { label: "Donations Received", value: 50000 }
          ].map((stat, index) => (
            <div key={index} className="text-center">
              <CountUp start={0} end={stat.value} duration={2.5} className="text-5xl font-bold text-rose-400" />
              <p className="text-xl text-gray-700 mt-2">{stat.label}</p>
            </div>
          ))}
        </div>
      </section>
      <section className="relative py-16 bg-gradient-to-r from-rose-400 to-pink-500 text-center text-white">
      <h2 className="text-4xl font-bold mb-4 text-white">Together, We Rise</h2>
        <p className="text-lg mb-8">Join the movement to empower women and make a lasting impact.</p>
        <button className="bg-white text-rose-500 px-6 py-3 rounded-full font-semibold hover:bg-gray-100 transition">
          Get Involved
        </button>
      </section>




    </div>
  );
};

export default Home;
